import { Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  PageHeader,
  QueryTabs,
  QueryTabPanel,
  TradersBalance,
  MerchantsBalance,
} from 'components';
import { TranslationNamespace } from 'i18n';

enum AssetTab {
  TradersBalance = 'traders',
  MerchantsBalance = 'merchants',
}

export const TechOperatorMainPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.TechOperator, {
    keyPrefix: 'pages.assets',
  });

  return (
    <div>
      <PageHeader title={t('title')} divider />
      <QueryTabs tabsEnum={AssetTab}>
        <Tab
          value={AssetTab.TradersBalance}
          label={t('tabs.traders_balance')}
        />
        <Tab
          value={AssetTab.MerchantsBalance}
          label={t('tabs.merchants_balance')}
        />
      </QueryTabs>
      <QueryTabPanel value={AssetTab.TradersBalance}>
        <TradersBalance />
      </QueryTabPanel>
      <QueryTabPanel value={AssetTab.MerchantsBalance}>
        <MerchantsBalance />
      </QueryTabPanel>
    </div>
  );
};
