import { TextFieldProps } from '@mui/material';
import { filter, isEmpty, map } from 'lodash';
import React, { useMemo } from 'react';

import { FormikSelect } from 'components';
import { useCurrencies, useUserContext } from 'hooks';
import { tradeMethodsUtils } from 'utils';

type Props = {
  name: string;
  label: string;
  fiatCurrencyId?: string;
  crossFiatCurrencyId?: string;
  exclude?: string[];
} & Partial<TextFieldProps>;

export const TradeMethodSelect: React.FC<Props> = ({
  name,
  label,
  fiatCurrencyId,
  crossFiatCurrencyId,
  exclude,
  ...rest
}) => {
  const { tradeMethods, banks, paymentTypes } = useUserContext();
  const { fiatCurrencies } = useCurrencies();

  const fiatCurrenciesIds = useMemo(
    () => filter([fiatCurrencyId, crossFiatCurrencyId]),
    [crossFiatCurrencyId, fiatCurrencyId],
  );

  const tradeMethodsOptions = useMemo(
    () =>
      map(
        filter(
          tradeMethods,
          (tradeMethod) =>
            isEmpty(fiatCurrenciesIds) ||
            (fiatCurrenciesIds.includes(tradeMethod.fiatCurrencyId) &&
              !exclude?.includes(tradeMethod.id)),
        ),
        ({ id }) => ({
          value: id,
          label: tradeMethodsUtils.getTradeMethodLabel({
            tradeMethodId: id,
            tradeMethods,
            banks,
            paymentTypes,
            fiatCurrencies,
          }),
        }),
      ),
    [
      tradeMethods,
      fiatCurrenciesIds,
      exclude,
      banks,
      paymentTypes,
      fiatCurrencies,
    ],
  );

  return (
    <FormikSelect
      noneOption
      label={label}
      {...rest}
      name={name}
      options={tradeMethodsOptions}
    />
  );
};
