import { Button } from '@mui/material';
import { Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { every, filter, find } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult, useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { internalTransferApi } from 'api';
import {
  CloseDialogHandler,
  CloseDialogResult,
  DataWrapper,
  Dialog,
  FormActions,
  FormControls,
  FormikNumericField,
  FormikSelect,
  UserSelect,
} from 'components';
import { QueryKey, UserRole } from 'enums';
import { useCurrencies, useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Asset, Shop, User } from 'types';
import { assetUtils } from 'utils';

type Props = {
  open: boolean;
  queryResultUsers?: UseQueryResult<User[]>;
  queryResultShops?: UseQueryResult<Shop[]>;
  queryResultAssets: UseQueryResult<Asset[]>;
  onClose: CloseDialogHandler;
};

type Values = {
  senderUserId: string;
  senderAssetId: string;
  amount: number;
  recipientUserId: string;
  recipientAssetId: string;
};

export const AdminCreateInternalTransferDialog: React.FC<Props> = ({
  open,
  queryResultUsers,
  queryResultShops,
  queryResultAssets,
  onClose,
}) => {
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.internal_transfers',
  });
  const queryClient = useQueryClient();

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        amount: Yup.number()
          .positive(tCommon('errors.natural_number'))
          .required(tCommon('errors.required')),
        senderUserId: Yup.string()
          .required(tCommon('errors.required'))
          .uuid(tCommon('errors.invalid')),
        senderAssetId: Yup.string()
          .required(tCommon('errors.required'))
          .uuid(tCommon('errors.invalid')),
        recipientUserId: Yup.string()
          .required(tCommon('errors.required'))
          .uuid(tCommon('errors.invalid')),
        recipientAssetId: Yup.string()
          .required(tCommon('errors.required'))
          .uuid(tCommon('errors.invalid')),
      }),
    [tCommon],
  );

  const initialValues: Values = useMemo(
    () => ({
      senderUserId: '',
      senderAssetId: '',
      amount: 0,
      recipientUserId: '',
      recipientAssetId: '',
    }),
    [],
  );

  const { mutate: create, isLoading: isCreateLoading } = useMutation(
    internalTransferApi.createAdmin,
  );
  const isLoading = useMemo(
    () =>
      queryResultUsers?.isLoading ||
      queryResultShops?.isLoading ||
      isCreateLoading,
    [queryResultUsers, queryResultShops, isCreateLoading],
  );

  const availableUsers = useMemo(
    () =>
      filter(
        queryResultUsers?.data,
        (user) =>
          ![UserRole.Admin, UserRole.Operator, UserRole.TechOperator].includes(
            user.role,
          ),
      ),
    [queryResultUsers?.data],
  );

  const handleClose = useCallback(
    (result: CloseDialogResult, helpers: FormikHelpers<Values>) => {
      helpers.resetForm();
      onClose(result);
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    (values: Values, helpers: FormikHelpers<Values>) => {
      create(
        {
          senderUserId: values.senderUserId,
          senderAssetId: values.senderAssetId,
          amount: +values.amount,
          recipientUserId: values.recipientUserId,
          recipientAssetId: values.recipientAssetId,
        },
        {
          onSettled: () => {
            helpers.setSubmitting(false);
          },
          onSuccess: () => {
            queryClient.invalidateQueries(QueryKey.Assets);
            queryClient.invalidateQueries(QueryKey.InternalTransfers);

            handleClose({ ok: true }, helpers);
          },
        },
      );
    },
    [create, queryClient, handleClose],
  );

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  const { values, setFieldError } = formik;

  const getAssets = useCallback(
    (userId: string, currencyId?: string) =>
      assetUtils.filterAssets(
        queryResultAssets.data,
        { userId, currencyId },
        queryResultShops?.data,
      ),
    [queryResultAssets.data, queryResultShops?.data],
  );

  const senderAssets = useMemo(
    () => getAssets(values.senderUserId),
    [getAssets, values.senderUserId],
  );

  const senderAssetOptions = useMemo(
    () => assetUtils.getAssetOptions(senderAssets),
    [senderAssets],
  );

  const selectedSenderAsset = useMemo(
    () => find(senderAssets, { id: values.senderAssetId }),
    [senderAssets, values.senderAssetId],
  );

  const recipientAssets = useMemo(
    () =>
      getAssets(values.recipientUserId, selectedSenderAsset?.assetCurrencyId),
    [getAssets, values.recipientUserId, selectedSenderAsset],
  );

  const recipientNoAssetCreated = useMemo(
    () =>
      recipientAssets.length === 1 &&
      every(recipientAssets, (asset) => !asset.id),
    [recipientAssets],
  );

  const recipientAssetOptions = useMemo(
    () => assetUtils.getAssetOptions(recipientAssets),
    [recipientAssets],
  );

  const { getAssetCurrencySymbol } = useCurrencies();
  const getAssetCurrencySymbolByAssetId = useCallback(
    (assetId: string, assets: Asset[]) => {
      const selectedAsset = find(assets, { id: assetId });
      return getAssetCurrencySymbol(selectedAsset?.assetCurrencyId);
    },
    [getAssetCurrencySymbol],
  );

  // TODO: no highlight, only with hot reload
  useEffect(() => {
    if (selectedSenderAsset && selectedSenderAsset.balance < values.amount) {
      setFieldError('amount', tCommon('errors.not_enough_money'));
    }
  }, [selectedSenderAsset, tCommon, values.amount, setFieldError]);

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={open}
        title={t('create_admin_modal.title')}
        data={{ values, formikHelpers: formik }}
        onClose={(result) => handleClose(result, formik)}
        modal
      >
        <DataWrapper isLoading={isLoading}>
          <Form>
            <FormControls>
              <UserSelect
                name="senderUserId"
                fullWidth
                label={t('create_admin_modal.sender.user')}
                users={availableUsers}
              />
              <FormikSelect
                label={t('create_admin_modal.sender.asset')}
                name="senderAssetId"
                options={senderAssetOptions}
                disabled={!values.senderUserId}
              />
              <FormikNumericField
                label={t('fields.amount')}
                name="amount"
                allowNegative={false}
                disabled={!values.senderAssetId}
                suffix={getAssetCurrencySymbolByAssetId(
                  values.senderAssetId,
                  senderAssets,
                )}
              />
              <UserSelect
                name="recipientUserId"
                fullWidth
                label={t('create_admin_modal.recipient.user')}
                users={availableUsers}
              />
              {!recipientNoAssetCreated && (
                <FormikSelect
                  label={t('create_admin_modal.recipient.asset')}
                  name="recipientAssetId"
                  options={recipientAssetOptions}
                  disabled={!values.recipientUserId}
                />
              )}
            </FormControls>
            <FormActions>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={isLoading || formik.isSubmitting}
              >
                {tCommon('buttons.create')}
              </Button>
            </FormActions>
          </Form>
        </DataWrapper>
      </Dialog>
    </FormikProvider>
  );
};
