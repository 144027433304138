import { Stack } from '@mui/material';
import { useQuery } from 'react-query';

import { assetsApi, tradersApi } from 'api';
import { AssetsTable, TotalBalance } from 'components';
import { QueryKey, UserRole } from 'enums';
import { useCalculateAssetBalances, useUser } from 'hooks';

export const TradersBalance: React.FC = () => {
  const { role } = useUser();
  const queryResult = useQuery(
    QueryKey.TradersAssets,
    assetsApi.getAllTradersAsRole(role),
  );
  const { balances } = useCalculateAssetBalances(queryResult.data || []);

  const tradersQueryResult = useQuery(QueryKey.Traders, () =>
    tradersApi.getAllAsRole(role)(),
  );

  return (
    <Stack spacing={4}>
      <TotalBalance balances={balances} />
      <AssetsTable
        role={UserRole.Trader}
        traders={tradersQueryResult.data}
        queryResult={queryResult}
      />
    </Stack>
  );
};
