import i18next from 'i18next';
import { constant, isNumber, times } from 'lodash';
import moment from 'moment';

import { ROUND_DECIMALS } from 'constants/common.constants';
import { DateFormat } from 'enums';

const CARD_LAST_DIGITS_LENGTH = 4;
const CARD_FIRST_DIGITS_LENGTH = 6;
const CARD_INFO_LENGTH = 16;
const CARD_SUBSTITUTION = times(
  CARD_INFO_LENGTH - CARD_FIRST_DIGITS_LENGTH - CARD_LAST_DIGITS_LENGTH,
  constant('*'),
).join('');

export const formatDate = (
  date: Date | string | undefined,
  format: DateFormat = DateFormat.Long,
  defaultValue = '',
) => {
  if (!date) {
    return defaultValue;
  }
  let resultFormat = format;
  if (format === DateFormat.Auto) {
    const now = moment();
    const isToday = now.isSame(date, 'day');
    resultFormat = isToday ? DateFormat.Short : DateFormat.Long;
  }
  return moment(date).local().format(resultFormat);
};

const formatCardInfo = (
  cardInfo?: string | number,
  options?: { firstOnly?: boolean; lastOnly?: boolean },
  defaultValue = '-',
) => {
  if (!cardInfo) {
    return defaultValue;
  }
  let card = cardInfo?.toString();
  if (options?.lastOnly) {
    const lastDigits = card.slice(-CARD_LAST_DIGITS_LENGTH);
    return `*${lastDigits}`;
  }
  if (options?.firstOnly) {
    const firstDigits = card.slice(-CARD_FIRST_DIGITS_LENGTH);
    card = `${firstDigits}*`;
  }
  return card.replace(/((\d|\*){4})/g, '$1 ');
};

const formatCardDigits = (
  {
    firstDigits,
    lastDigits,
  }: {
    firstDigits: string;
    lastDigits: string;
  },
  defaultValue = null,
) => {
  if (firstDigits && !lastDigits) {
    return formatCardInfo(firstDigits, { firstOnly: true });
  } else if (!firstDigits && lastDigits) {
    return formatCardInfo(lastDigits, { lastOnly: true });
  } else if (firstDigits && lastDigits) {
    return formatCardInfo(`${firstDigits}${CARD_SUBSTITUTION}${lastDigits}`);
  }
  return defaultValue;
};

const formatPhoneLastDigits = (
  phoneLastDigits?: string | number,
  defaultValue = '-',
) => {
  if (!phoneLastDigits) {
    return defaultValue;
  }

  return `*${phoneLastDigits}`;
};

const formatNumber = (value?: number, options?: { sign: boolean }) => {
  if (!isNumber(value)) {
    return null;
  }
  const formatOptions: Intl.NumberFormatOptions = {
    minimumFractionDigits: 0,
    maximumFractionDigits: ROUND_DECIMALS,
    ...(options?.sign && { signDisplay: 'exceptZero' }),
  };
  return new Intl.NumberFormat('ru', formatOptions).format(value);
};

const formatMoney = (value: number) => {
  const formatOptions: Intl.NumberFormatOptions = {
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: ROUND_DECIMALS,
  };
  return new Intl.NumberFormat('ru', formatOptions).format(value);
};

const formatBoolean = (value?: boolean) => {
  const { t } = i18next;
  const key = `common.${value ? 'yes' : 'no'}`;
  return i18next.exists(key) ? t(key as any) : key;
};

export const formatUtils = {
  formatDate,
  formatCardInfo,
  formatCardDigits,
  formatPhoneLastDigits,
  formatMoney,
  formatBoolean,
  formatNumber,
};
