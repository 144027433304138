import { Link } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useMutation } from 'react-query';

import { ordersApi, payoutOrdersApi } from 'api';
import { OrderType } from 'enums';
import { useUser } from 'hooks';

type Props = {
  orderId: string;
  orderType: OrderType;
  filename: string;
};

export const ReceiptLabel: React.FC<Props> = ({
  orderId,
  orderType,
  filename,
}) => {
  const { role } = useUser();
  const mutationFn = useMemo(
    () =>
      orderType === OrderType.Payin
        ? ordersApi.generateReceiptUrlAsRole(role)
        : payoutOrdersApi.generateReceiptUrlAsRole(role),
    [orderType, role],
  );

  const { mutate: generateUrl, isLoading } = useMutation(mutationFn, {
    onSuccess: ({ url }) => {
      window.open(url, '_blank', 'noopener,noreferrer');
    },
  });

  const handleClick = useCallback(() => {
    generateUrl({
      orderId,
      body: { filename },
    });
  }, [filename, generateUrl, orderId]);

  return (
    <Link
      component="button"
      variant="body2"
      underline="none"
      textAlign="left"
      sx={{ ...(isLoading && { opacity: 0.5 }) }}
      disabled={isLoading}
      onClick={handleClick}
    >
      {filename}
    </Link>
  );
};
