import { Tab } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  PageHeader,
  QueryTabPanel,
  QueryTabs,
  TradersBalance,
} from 'components';
import { TranslationNamespace } from 'i18n';

enum AssetTab {
  TradersBalance = 'traders',
}

export const OperatorAssetsPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Operator, {
    keyPrefix: 'pages.assets',
  });

  return (
    <div>
      <PageHeader title={t('title')} divider />
      <QueryTabs tabsEnum={AssetTab}>
        <Tab
          value={AssetTab.TradersBalance}
          label={t('tabs.traders_balance')}
        />
      </QueryTabs>
      <QueryTabPanel value={AssetTab.TradersBalance}>
        <TradersBalance />
      </QueryTabPanel>
    </div>
  );
};
