import { CircularProgress } from '@mui/material';
import { some } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  assetCurrencyApi,
  authApi,
  banksApi,
  currencyExchangeApi,
  fiatCurrencyApi,
  metaApi,
  paymentTypesApi,
  tradeMethodsApi,
  usersApi,
  sciDomainsApi,
} from 'api';
import { LayoutMenuSection, MainLayout } from 'components';
import { ROUTE_PATH } from 'constants/routes';
import { UserContext, UserContextProps } from 'context';
import { QueryKey } from 'enums';
import { useMutation } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { User } from 'types';
import { authUtils } from 'utils';

type Props = {
  menuSections?: LayoutMenuSection[];
  children: React.ReactElement;
};

export const UserLayout: React.FC<Props> = ({ menuSections, children }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'layout.user',
  });
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const localLogout = useCallback(() => {
    authUtils.resetAuthData();
    navigate(ROUTE_PATH.PUBLIC.LOGIN);
  }, [navigate]);

  const profileQueryResult = useQuery(QueryKey.Profile, usersApi.getProfile, {
    onError: () => {
      // if first load
      if (!profileQueryResult.data) {
        toast.error(t('load_profile_error'));
        localLogout();
      }
    },
  });
  const user = useMemo(
    () => profileQueryResult.data as User,
    [profileQueryResult],
  );

  const { mutate: logout } = useMutation(authApi.logout, {
    onSettled: () => {
      queryClient.clear();
      localLogout();
    },
  });

  const fiatCurrenciesQueryResult = useQuery(QueryKey.FiatCurrencies, () =>
    fiatCurrencyApi.getAll(),
  );

  const assetCurrenciesQueryResult = useQuery(QueryKey.AssetCurrencies, () =>
    assetCurrencyApi.getAll(),
  );

  const banksQueryResult = useQuery(QueryKey.Banks, () => banksApi.getAll());

  const paymentTypesQueryResult = useQuery(QueryKey.PaymentTypes, () =>
    paymentTypesApi.getAll(),
  );

  const tradeMethodsQueryResult = useQuery(QueryKey.TradeMethods, () =>
    tradeMethodsApi.getAll(),
  );

  const currencyExchangeResult = useQuery(QueryKey.CurrencyExchange, () =>
    currencyExchangeApi.getAll(),
  );

  const metaQueryResult = useQuery(QueryKey.Meta, () => metaApi.getAll());

  const sciDomainsQueryResult = useQuery(QueryKey.SciDomains, () =>
    sciDomainsApi.getAll(),
  );

  const contextValue: UserContextProps = useMemo(
    () => ({
      user,
      assetCurrencies: assetCurrenciesQueryResult.data || [],
      fiatCurrencies: fiatCurrenciesQueryResult.data || [],
      banks: banksQueryResult.data || [],
      paymentTypes: paymentTypesQueryResult.data || [],
      tradeMethods: tradeMethodsQueryResult.data || [],
      currencyExchanges: currencyExchangeResult.data || [],
      meta: metaQueryResult.data || [],
      sciDomains: sciDomainsQueryResult.data || [],
    }),
    [
      assetCurrenciesQueryResult.data,
      banksQueryResult.data,
      fiatCurrenciesQueryResult.data,
      paymentTypesQueryResult.data,
      tradeMethodsQueryResult.data,
      metaQueryResult.data,
      currencyExchangeResult.data,
      sciDomainsQueryResult.data,
      user,
    ],
  );

  const queries = useMemo(
    () => [
      assetCurrenciesQueryResult,
      fiatCurrenciesQueryResult,
      banksQueryResult,
      paymentTypesQueryResult,
      tradeMethodsQueryResult,
      metaQueryResult,
    ],
    [
      assetCurrenciesQueryResult,
      banksQueryResult,
      fiatCurrenciesQueryResult,
      paymentTypesQueryResult,
      tradeMethodsQueryResult,
      metaQueryResult,
    ],
  );

  if (
    !user ||
    some<UseQueryResult>(queries, (query) => !query.data || query.isLoading)
  ) {
    return (
      <div className="tw-text-center tw-my-8">
        <CircularProgress />
      </div>
    );
  }

  return (
    <UserContext.Provider value={contextValue}>
      <MainLayout menuSections={menuSections ?? []} onLogout={logout}>
        {children}
      </MainLayout>
    </UserContext.Provider>
  );
};
