import i18next from 'i18next';
import { find } from 'lodash';
import { Fragment } from 'react';

import {
  BalanceChange,
  CopyText,
  CopyTextId,
  DataGridColumnDefinition,
  Money,
} from 'components';
import { AssetChangeReason, FilterDefinitionType, OrderType } from 'enums';
import { AssetCurrencyCode } from 'features';
import {
  AssetBalanceHistory,
  AssetBalanceHistoryFilters,
  FilterDefinition,
  Shop,
  User,
} from 'types';
import { formatUtils } from 'utils';

const getOrderTypeLabel = (item: AssetBalanceHistory) => {
  let orderType;

  if (!!item.orderId) {
    orderType = OrderType.Payin;
  } else if (!!item.payoutOrderId) {
    orderType = OrderType.Payout;
  }

  const { t } = i18next;
  const key = `common.${orderType}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getAssetChangeReasonLabel = (
  reason: AssetChangeReason,
  orderTypeLabel?: string,
) => {
  const { t } = i18next;
  const key = `features.asset_balance_history.reason.${reason}`;
  return i18next.exists(key)
    ? t(key as any, orderTypeLabel ? { orderType: `(${orderTypeLabel})` } : {})
    : key;
};

const getCommonColumns =
  (): DataGridColumnDefinition<AssetBalanceHistory>[] => {
    const { t } = i18next;
    const prefix = 'features.asset_balance_history.columns';
    return [
      {
        header: t(`${prefix}.asset`),
        valueGetter: (item) => <CopyText text={item.assetId} iconOnly />,
      },
      {
        header: t(`${prefix}.currency`),
        valueGetter: (item) => (
          <AssetCurrencyCode id={item.asset?.assetCurrencyId || ''} />
        ),
      },
      {
        header: t(`${prefix}.date`),
        valueGetter: (item) => formatUtils.formatDate(item.createdAt),
      },
      {
        header: t(`${prefix}.balance`),
        valueGetter: (item) => (
          <span>
            <span className="tw-mr-0.5">
              {formatUtils.formatMoney(item.balance)}
            </span>
            <BalanceChange value={item.balanceChange} />
          </span>
        ),
      },
      {
        header: t(`${prefix}.hold_balance`),
        valueGetter: (item) => (
          <span>
            <span className="tw-mr-0.5">
              {formatUtils.formatMoney(item.holdBalance)}
            </span>
            <BalanceChange value={item.holdBalanceChange} />
          </span>
        ),
      },
      {
        header: t(`${prefix}.reason`),
        valueGetter: (item) => {
          const order = item.order || item.payoutOrder;
          return (
            <Fragment>
              <div>
                {assetBalanceHistoryUtils.getAssetChangeReasonLabel(
                  item.assetChangeReason,
                  getOrderTypeLabel(item),
                )}
              </div>
              {order && (
                <div>
                  <span>{`${t(`${prefix}.currency_rate`)}: `}</span>
                  <span>
                    <Money
                      value={order.currencyRate}
                      assetCurrencyId={order.assetCurrencyId}
                      symbol
                    />
                  </span>
                </div>
              )}
              {item.fundsRequestId && <CopyTextId id={item.fundsRequestId} />}
            </Fragment>
          );
        },
      },
    ];
  };

const getCommonFilters = (): FilterDefinition<AssetBalanceHistoryFilters>[] => {
  const { t } = i18next;
  const prefix = 'features.asset_balance_history.filters';
  return [
    {
      label: t(`${prefix}.order_id`),
      name: 'orderId',
      type: FilterDefinitionType.Text,
      format: 'uuid',
    },
    {
      label: t(`${prefix}.payout_order_id`),
      name: 'payoutOrderId',
      type: FilterDefinitionType.Text,
      format: 'uuid',
    },
    {
      label: t(`${prefix}.asset_id`),
      name: 'assetId',
      type: FilterDefinitionType.Text,
      format: 'uuid',
    },
  ];
};

const getAdminColumns = (): DataGridColumnDefinition<AssetBalanceHistory>[] => {
  const { t } = i18next;
  const prefix = 'features.asset_balance_history.columns';
  return [
    {
      header: t(`${prefix}.user`),
      valueGetter: (item) => (
        <Fragment>
          {item.asset?.user && (
            <Fragment>
              <div>{item.asset.user.name}</div>
              <div>{item.asset.user.email}</div>
            </Fragment>
          )}
          {item.asset?.shop && <div>{item.asset.shop.name}</div>}
        </Fragment>
      ),
    },
  ];
};

const getMerchantColumns = (
  shops: Shop[],
): DataGridColumnDefinition<AssetBalanceHistory>[] => {
  const { t } = i18next;
  const prefix = 'features.asset_balance_history.columns';
  return [
    {
      header: t(`${prefix}.shop`),
      valueGetter: (item) => {
        const shop = find(shops, { id: item.asset?.shopId });
        return <div>{shop?.name}</div>;
      },
    },
  ];
};

const getAdminFilters = (
  users: User[],
): FilterDefinition<AssetBalanceHistoryFilters>[] => {
  const { t } = i18next;
  const prefix = 'features.asset_balance_history.filters';
  return [
    {
      label: t(`${prefix}.user`),
      name: 'userId',
      type: FilterDefinitionType.User,
      users,
      getDisplayName: (userId: string) => find(users, { id: userId })?.name,
    },
  ];
};

const getShopFilters = (
  shops: Shop[],
): FilterDefinition<AssetBalanceHistoryFilters>[] => {
  const { t } = i18next;
  const prefix = 'features.asset_balance_history.filters';
  return [
    {
      label: t(`${prefix}.shop`),
      name: 'shopId',
      type: FilterDefinitionType.Shop,
      shops,
      getDisplayName: (shopId: string) => find(shops, { id: shopId })?.name,
    },
  ];
};

export const assetBalanceHistoryUtils = {
  getCommonColumns,
  getCommonFilters,
  getAdminColumns,
  getTechOperatorColumns: getAdminColumns,
  getAdminFilters,
  getTechOperatorFilters: getAdminFilters,
  getShopFilters,
  getMerchantColumns,
  getAssetChangeReasonLabel,
};
