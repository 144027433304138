import { TextFieldProps } from '@mui/material';
import { isNil, map, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { tradersApi } from 'api';
import { FormikSelect } from 'components';
import { QueryKey } from 'enums';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Trader } from 'types';

type Props = {
  name: string;
  label?: string;
  traders?: Trader[];
} & Partial<TextFieldProps>;

export const TraderSelect: React.FC<Props> = ({
  name,
  traders,
  label,
  ...rest
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.trader_select',
  });
  const { role } = useUser();

  const queryResult = useQuery(
    QueryKey.Traders,
    () => tradersApi.getAllAsRole(role)(),
    { enabled: isNil(traders) },
  );

  const options = useMemo(
    () =>
      map(
        sortBy(traders || queryResult?.data, (trader) => trader.user?.name),
        (trader) => ({
          value: trader.id,
          label: trader.user?.name || '',
        }),
      ),
    [traders, queryResult],
  );

  return (
    <FormikSelect
      noneOption
      label={label || t('label')}
      {...rest}
      name={name}
      options={options}
    />
  );
};
