import {
  Payments as PaymentsIcon,
  LocalMall as LocalMallIcon,
  AttachMoney as AttachMoneyIcon,
  Assignment as AssignmentIcon,
  Timeline as TimelineIcon,
  Webhook as WebhookIcon,
  QueryStats as QueryStatsIcon,
  CompareArrows as CompareArrowsIcon,
} from '@mui/icons-material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LayoutMenuSection } from 'components';
import { ROUTE_PATH } from 'constants/routes';
import { TranslationNamespace } from 'i18n';
import { UserLayout } from 'layouts/User';

type Props = {
  children: React.ReactElement;
};

export const MerchantLayout: React.FC<Props> = ({ children }: Props) => {
  const { t } = useTranslation(TranslationNamespace.Merchant, {
    keyPrefix: 'layout',
  });

  const menuSections: LayoutMenuSection[] = useMemo(
    () => [
      {
        title: t('menu_sections.assets'),
        items: [
          {
            title: t('menu_items.assets.balances'),
            MuiIcon: AttachMoneyIcon,
            link: ROUTE_PATH.MERCHANT.MAIN,
          },
          {
            title: t('menu_items.assets.funds_requests'),
            MuiIcon: PaymentsIcon,
            link: ROUTE_PATH.MERCHANT.FUNDS_REQUESTS,
          },
          {
            title: t('menu_items.assets.internal_transfers'),
            MuiIcon: CompareArrowsIcon,
            link: ROUTE_PATH.MERCHANT.INTERNAL_TRANSFERS,
          },
          {
            title: t('menu_items.assets.balance_history'),
            MuiIcon: TimelineIcon,
            link: ROUTE_PATH.MERCHANT.BALANCE_HISTORY,
          },
        ],
      },
      {
        title: t('menu_sections.main'),
        items: [
          {
            title: t('menu_items.shops'),
            MuiIcon: LocalMallIcon,
            link: ROUTE_PATH.MERCHANT.SHOPS,
          },
          {
            title: t('menu_items.orders'),
            MuiIcon: AssignmentIcon,
            link: ROUTE_PATH.MERCHANT.ORDERS,
          },
          {
            title: t('menu_items.webhooks'),
            MuiIcon: WebhookIcon,
            link: ROUTE_PATH.MERCHANT.WEBHOOKS,
          },
          {
            title: t('menu_items.statistics'),
            MuiIcon: QueryStatsIcon,
            link: ROUTE_PATH.MERCHANT.STATISTICS,
          },
        ],
      },
    ],
    [t],
  );

  return <UserLayout menuSections={menuSections} children={children} />;
};
