import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AdditionalOrderColumn,
  PayoutDisputeStatusDetails,
  FilterDefinitionType,
  OrdersTab,
} from 'enums';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, OrderFilters } from 'types';
import { orderUtils } from 'utils';

import { ManageOrdersList } from '../ManageOrderList';

export const DisputeOrders: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.dispute',
  });

  const additionalFilters: FilterDefinition<OrderFilters>[] = useMemo(
    () => [
      {
        label: t('title'),
        name: 'statusDetails',
        type: FilterDefinitionType.Enum,
        enum: Object.values(PayoutDisputeStatusDetails),
        getDisplayName: (value: PayoutDisputeStatusDetails) =>
          orderUtils.getPayoutStatusDetailsLabel(value),
      },
    ],
    [t],
  );

  return (
    <ManageOrdersList
      tab={OrdersTab.Dispute}
      additionalColumns={[
        AdditionalOrderColumn.CustomerPayment,
        AdditionalOrderColumn.TraderCard,
        AdditionalOrderColumn.Dispute,
        AdditionalOrderColumn.CustomerRequisites,
        AdditionalOrderColumn.Validation,
      ]}
      additionalFilters={additionalFilters}
    />
  );
};
