import { AxiosError } from 'axios';
import { FormikErrors } from 'formik';
import i18next from 'i18next';
import { forEach, isArray, keys, snakeCase } from 'lodash';
import * as Yup from 'yup';

import { ValidationError } from 'types';

import { formatUtils } from './format.utils';

const validators = {
  uuid: (value: string) =>
    !Yup.string().uuid().isValidSync(value) && value
      ? i18next.t('errors.invalid_uuid')
      : undefined,
} as const;

type ErrorOverrides<T = any> = {
  [property in keyof T]: {
    [constraint: string]: string;
  };
};

const getConstraintTKey = (constraint: string) => {
  switch (constraint) {
    case 'isEmail':
      return 'email';
    default:
      return snakeCase(constraint);
  }
};

const getConstraintTranslate = (
  constraint: string,
  error: ValidationError,
  overrides?: ErrorOverrides,
) => {
  const override = overrides?.[error.property]?.[constraint];
  if (override) {
    return override;
  }
  if (constraint === 'max') {
    const lastSpace = error.constraints?.[constraint].lastIndexOf(' ');
    const maxValue = error.constraints?.[constraint].slice(lastSpace) || 0;
    return `${i18next.t(`errors.${constraint}`)} ${formatUtils.formatMoney(
      +maxValue,
    )}`;
  }
  if (constraint === 'too_big' && error.property === 'amount') {
    return i18next.t(`errors.not_enough_money`);
  }
  if (
    constraint === 'bad_currencies_spread' &&
    error.property === 'fiatCurrencyId'
  ) {
    return i18next.t(`errors.bad_currencies_spread`);
  }
  const tKey = getConstraintTKey(constraint);
  return i18next.exists(`errors.${tKey}_${error.property}`)
    ? i18next.t(`errors.${tKey}_${error.property}` as any)
    : i18next.exists(`errors.${tKey}` as any)
    ? i18next.t(`errors.${tKey}` as any)
    : error.constraints?.[constraint];
};

const getValidationErrorMessage = (
  error: ValidationError,
  overrides?: ErrorOverrides,
) => {
  const constraint = keys(error?.constraints)?.[0];
  return getConstraintTranslate(constraint, error, overrides);
};

const getFormErrors = <Values = any>(
  error: AxiosError,
  overrides?: ErrorOverrides<Values>,
) => {
  const validationErrors = (error as AxiosError<{ message: ValidationError[] }>)
    ?.response?.data.message;
  const errors: FormikErrors<any> = {};
  if (isArray(validationErrors)) {
    forEach(validationErrors, (error) => {
      errors[error.property] = getValidationErrorMessage(error, overrides);
    });
  }
  return errors as FormikErrors<Values>;
};

export const validationUtils = {
  getFormErrors,
  validators,
};
