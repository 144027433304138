import { MenuItem, TextFieldProps } from '@mui/material';
import { Field, useField } from 'formik';
import { TextField } from 'formik-mui';
import { find, map, sortBy } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';

export type FormikSelectOption = {
  label: React.ReactNode | string;
  value: string;
  disabled?: boolean;
};

type Props = {
  label: React.ReactNode | string;
  name: string;
  options: FormikSelectOption[];
  noneOption?: boolean;
  showMetaError?: boolean;
} & Partial<TextFieldProps>;

export type { Props as FormikSelectProps };

export const FormikSelect: React.FC<Props> = ({
  label,
  name,
  options,
  noneOption,
  showMetaError,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.formik_select',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const [field, meta] = useField(name);

  const isSelectedDisabled = useMemo(
    () => find(options, { value: field.value })?.disabled,
    [field.value, options],
  );

  return (
    <Field
      {...rest}
      component={TextField}
      name={name}
      label={label}
      select
      size="small"
      {...(isSelectedDisabled && {
        error: true,
        helperText: tCommon('errors.invalid'),
      })}
      {...(showMetaError &&
        meta.error && {
          error: true,
          helperText: meta.error,
        })}
    >
      {noneOption && <MenuItem value={''}>{t('none_option')}</MenuItem>}
      {map(sortBy(options, 'disabled'), (option) => (
        <MenuItem
          key={option.value}
          value={option.value}
          disabled={option.disabled}
        >
          {option.label}
        </MenuItem>
      ))}
    </Field>
  );
};
