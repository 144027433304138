import { Button, Divider, Stack } from '@mui/material';
import { AxiosError } from 'axios';
import {
  Field,
  Form,
  FormikHelpers,
  FormikProps,
  FormikProvider,
  useFormik,
} from 'formik';
import { TextField } from 'formik-mui';
import { includes } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';
import * as Yup from 'yup';

import { invitesApi, usersApi } from 'api';
import {
  CloseDialogResult,
  CloseDialogHandler,
  CopyText,
  Dialog,
  ExternalLink,
  FormikNumericField,
  PlatformWalletSelect,
  RoleLabel,
  RoleSelect,
  UserReferrerFormFields,
  REFERRER_VALIDATION_SCHEMA,
} from 'components';
import { QueryKey, UserRole } from 'enums';
import { useCurrencies, useMutation, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Invite } from 'types';
import { invitesUtils, validationUtils } from 'utils';

type Props = {
  open: boolean;
  onClose: CloseDialogHandler;
};

type Values = Pick<
  Invite,
  | 'email'
  | 'role'
  | 'referralUserId'
  | 'transactionPercentage'
  | 'payoutTransactionPercentage'
  | 'referralPayoutCompensationPercentage'
  | 'referralCompensationPercentage'
  | 'platformWalletId'
  | 'insuranceDepositAmount'
>;

export const InviteDialog: React.FC<Props> = ({ open, onClose }: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.invite_dialog',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { isTechOperator } = useUser();
  const { getDefaultAssetCurrency } = useCurrencies();
  const currencySymbol = useMemo(
    () => getDefaultAssetCurrency()?.symbol,
    [getDefaultAssetCurrency],
  );

  const inviteSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .required(tCommon('errors.required'))
          .email(tCommon('errors.email')),
        transactionPercentage: Yup.number()
          .min(0, tCommon('errors.percentage'))
          .lessThan(100, tCommon('errors.percentage')),
        payoutTransactionPercentage: Yup.number()
          .min(0, tCommon('errors.percentage'))
          .lessThan(100, tCommon('errors.percentage')),
        ...REFERRER_VALIDATION_SCHEMA,
        platformWalletId: Yup.string().when('role', {
          is: (role: UserRole) =>
            includes([UserRole.Trader, UserRole.Merchant], role),
          then: (schema) => schema.required(tCommon('errors.required')),
        }),
      }),
    [tCommon],
  );

  const initialValues: Values = useMemo(
    () => ({
      email: '',
      role: isTechOperator ? UserRole.TechOperator : UserRole.Admin,
      referralUserId: '',
      transactionPercentage: 0,
      payoutTransactionPercentage: 0,
      referralCompensationPercentage: 0,
      referralPayoutCompensationPercentage: 0,
      platformWalletId: '',
      insuranceDepositAmount: 0,
    }),
    [isTechOperator],
  );

  const queryClient = useQueryClient();

  const queryResultTraders = useQuery(
    QueryKey.UsersTraders,
    usersApi.getAllTraders,
  );

  const {
    mutate: createInvite,
    data: createdInvite,
    reset,
  } = useMutation(invitesApi.create, {
    onError: (error: AxiosError) => {
      formik.setErrors(validationUtils.getFormErrors(error));
    },
  });

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      formikHelpers.setSubmitting(true);
      createInvite(values, {
        onSettled: () => {
          queryClient.invalidateQueries(QueryKey.Users);
          queryClient.invalidateQueries(QueryKey.UsersTraders);
          formikHelpers.setSubmitting(false);
        },
      });
    },
    [queryClient, createInvite],
  );

  const resetComponent = useCallback(
    (formik: FormikProps<Values>) => {
      reset();
      formik.resetForm();
    },
    [reset],
  );

  const handleClose = useCallback(
    ({ ok }: CloseDialogResult<Values>, formik: FormikProps<Values>) => {
      if (!ok) {
        resetComponent(formik);
        onClose({ ok });
        return;
      }
      if (!createdInvite) {
        formik.submitForm();
        return;
      }
      resetComponent(formik);
      onClose({ ok: true });
    },
    [createdInvite, onClose, resetComponent],
  );

  const inviteLink = useMemo(
    () => (createdInvite ? invitesUtils.getLink(createdInvite?.token) : ''),
    [createdInvite],
  );

  const formik = useFormik({
    initialValues,
    validationSchema: inviteSchema,
    onSubmit: handleSubmit,
  });

  const isTraderSelected = useMemo(
    () => formik.values.role === UserRole.Trader,
    [formik.values.role],
  );

  const isMerchantSelected = useMemo(
    () => formik.values.role === UserRole.Merchant,
    [formik.values.role],
  );

  const isReferrerSelected = useMemo(
    () => !!formik.values.referralUserId,
    [formik.values.referralUserId],
  );

  return (
    <FormikProvider value={formik}>
      <Dialog
        open={open}
        title={createdInvite ? t('created') : t('title')}
        okDisabled={formik.isSubmitting}
        modal={!!createdInvite}
        onClose={(data) => handleClose(data, formik)}
      >
        {!createdInvite && (
          <Form className="tw-grid tw-gap-4">
            <Stack
              spacing={6}
              divider={<Divider orientation="horizontal" flexItem />}
            >
              <Stack spacing={4}>
                <Field
                  component={TextField}
                  label={t('fields.email')}
                  name="email"
                  type="email"
                />
                <RoleSelect
                  name="role"
                  excludeRoles={isTechOperator ? [UserRole.Admin] : []}
                />
                {(isTraderSelected || isMerchantSelected) && (
                  <Fragment>
                    <Stack direction="row" spacing={3}>
                      <FormikNumericField
                        name="transactionPercentage"
                        label={
                          isTraderSelected
                            ? t('fields.compensation')
                            : t('fields.commission')
                        }
                        percentageSuffix
                        fullWidth
                      />
                      <FormikNumericField
                        name="payoutTransactionPercentage"
                        label={
                          isTraderSelected
                            ? t('fields.payout_compensation')
                            : t('fields.payout_commission')
                        }
                        percentageSuffix
                        fullWidth
                      />
                    </Stack>
                    <PlatformWalletSelect
                      label={t('fields.platform_wallet')}
                      name="platformWalletId"
                    />
                  </Fragment>
                )}
                {isTraderSelected && (
                  <FormikNumericField
                    name="insuranceDepositAmount"
                    label={t('fields.insurance_deposit_amount')}
                    allowNegative={false}
                    decimalScale={0}
                    suffix={currencySymbol}
                    fullWidth
                  />
                )}
              </Stack>
              {(isTraderSelected || isMerchantSelected) && (
                <Stack spacing={4}>
                  {/* TODO: fix for SPAY-1480. To be removed after migration */}
                  <UserReferrerFormFields
                    canChangeCompensation={isReferrerSelected}
                    users={queryResultTraders.data}
                  />
                </Stack>
              )}
            </Stack>
          </Form>
        )}
        {createdInvite && (
          <div>
            <div className="tw-my-4">
              <div className="tw-flex">
                <span className="tw-mr-1 tw-font-bold tw-mb-2">
                  {`${t('fields.role')}:`}
                </span>
                <RoleLabel role={createdInvite.role} />
              </div>
              <div className="tw-flex">
                <span className="tw-mr-1 tw-font-bold tw-mb-2">
                  {`${t('fields.email')}:`}
                </span>
                {createdInvite.email}
              </div>
              <div className="tw-flex">
                <ExternalLink href={inviteLink} />
                <CopyText
                  className="tw-inline-flex"
                  text={inviteLink}
                  iconOnly
                />
              </div>
            </div>
            <div className="tw-text-center tw-mt-4">
              <Button variant="outlined" onClick={() => resetComponent(formik)}>
                {t('create_more')}
              </Button>
            </div>
          </div>
        )}
      </Dialog>
    </FormikProvider>
  );
};
