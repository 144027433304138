import { Divider } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { assign, keys, map, pick } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import {
  CloseFormikDialogResult,
  Dialog,
  DialogProps,
  FormControls,
  FormikCheckbox,
  FormikNumericField,
  FormikSelect,
  FormikYesNoRadioGroup,
} from 'components';
import { TranslationNamespace } from 'i18n';
import { Bank, Currency, PaymentType, TradeMethodDto } from 'types';
import { formUtils } from 'utils';

type Values = TradeMethodDto;

type Props = {
  banks: Bank[];
  paymentTypes: PaymentType[];
  fiatCurrencies: Currency[];
} & DialogProps<CloseFormikDialogResult<Values>>;

export const TradeMethodDetailsDialog: React.FC<Props> = ({
  open,
  data,
  banks,
  paymentTypes,
  fiatCurrencies,
  onClose,
  ...rest
}) => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.trade_methods',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const title = useMemo(
    () =>
      data ? t('details_dialog.edit_title') : t('details_dialog.create_title'),
    [t, data],
  );

  const getInitialValues = useCallback(
    () => ({
      bankId: '',
      fiatCurrencyId: '',
      paymentTypeId: '',
      enabled: true,
      useDistributionCache: true,
      parallelGroupOrdersEnabled: false,
      compareCardLast4DigitsEnabled: false,
      compareAccountLast4DigitsEnabled: false,
      payinConsecutiveCancellations: 0,
      crossCurrencyDeviation: 0,
    }),
    [],
  );

  const [initialValues, setInitialValues] = useState(getInitialValues());

  const validationSchema: Yup.ObjectSchema<Values> = useMemo(
    () =>
      Yup.object().shape({
        bankId: Yup.string().required(tCommon('errors.required')),
        fiatCurrencyId: Yup.string().required(tCommon('errors.required')),
        paymentTypeId: Yup.string().required(tCommon('errors.required')),
        enabled: Yup.boolean().required(tCommon('errors.required')),
        useDistributionCache: Yup.boolean().required(
          tCommon('errors.required'),
        ),
        parallelGroupOrdersEnabled: Yup.boolean().required(
          tCommon('errors.required'),
        ),
        compareCardLast4DigitsEnabled: Yup.boolean().required(
          tCommon('errors.required'),
        ),
        compareAccountLast4DigitsEnabled: Yup.boolean().required(
          tCommon('errors.required'),
        ),
        payinConsecutiveCancellations: Yup.number().required(),
        crossCurrencyDeviation: Yup.number().required(),
      }),
    [tCommon],
  );

  const handleClose = useCallback(
    (data: CloseFormikDialogResult<Values>) => {
      if (!data.ok) {
        data.data?.formikHelpers?.resetForm();
      }
      onClose(data);
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    (values: Values, formikHelpers: FormikHelpers<Values>) => {
      handleClose({ ok: true, data: { formikHelpers, values } });
    },
    [handleClose],
  );

  useEffect(() => {
    const _initialValues = getInitialValues();
    setInitialValues(assign(_initialValues, pick(data, keys(_initialValues))));
  }, [data, getInitialValues]);

  const paymentTypesOptions = useMemo(
    () =>
      formUtils.getOptions(
        map(paymentTypes, (paymentType) => ({
          ...paymentType,
          name: tCommon(`features.requisites.types.${paymentType.code}`, {
            defaultValue: paymentType.name,
          }),
        })),
      ),
    [paymentTypes, tCommon],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      validateOnMount
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Dialog
          open={open}
          title={title}
          data={{ values: formik.values, formikHelpers: formik }}
          onClose={handleClose}
          okDisabled={!formik.isValid}
          {...rest}
        >
          <Form>
            <FormControls>
              <FormikSelect
                label={t('fields.payment_type')}
                name="paymentTypeId"
                options={paymentTypesOptions}
                required
              />
              <FormikSelect
                label={t('fields.fiat_currency')}
                name="fiatCurrencyId"
                options={formUtils.getOptions(fiatCurrencies, 'code')}
                required
              />
              <FormikSelect
                label={t('fields.bank')}
                name="bankId"
                options={formUtils.getOptions(banks)}
                required
              />
              <FormikNumericField
                label={t('fields.payin_consecutive_cancellations')}
                helperText={t(
                  'fields.payin_consecutive_cancellations_description',
                )}
                name="payinConsecutiveCancellations"
                allowNegative={false}
                decimalScale={0}
                defaultValue={0}
              />
              <FormikYesNoRadioGroup
                name="enabled"
                label={t('fields.enabled')}
              />
              <FormikYesNoRadioGroup
                name="useDistributionCache"
                label={t('fields.use_distribution_cache')}
              />
              <Divider>{t('auto_close')}</Divider>
              <div>
                <FormikCheckbox
                  name="compareCardLast4DigitsEnabled"
                  label={t('fields.compare_card_last_4_digits_enabled')}
                />
                <FormikCheckbox
                  name="compareAccountLast4DigitsEnabled"
                  label={t('fields.compare_account_last_4_digits_enabled')}
                />
                <FormikCheckbox
                  name="parallelGroupOrdersEnabled"
                  label={t('fields.parallel_group_orders_enabled')}
                />
              </div>
              <FormikNumericField
                name="crossCurrencyDeviation"
                label={t('fields.cross_currency_deviation')}
                required
                allowNegative={false}
                percentageSuffix
              />
            </FormControls>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
};
