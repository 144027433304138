import { Receipt as ReceiptIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { every, isEmpty, map } from 'lodash';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayoutOrder } from 'types';
import { formatUtils } from 'utils';

type Props = {
  order: PayoutOrder;
  labelled?: boolean;
};

export const PayoutOrderReceiptsValidation: React.FC<Props> = ({
  order,
  labelled,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.receipt_validation',
  });
  const { isManager } = useUser();

  const showReceiptsValidation = useMemo(
    () => !isEmpty(order.receiptsValidation) && isManager,
    [isManager, order.receiptsValidation],
  );

  const validationSuccess = useMemo(
    () => every(order.receiptsValidation, (validation) => validation.success),
    [order.receiptsValidation],
  );

  const renderTooltipTitle = useCallback(
    () => (
      <Fragment>
        <div className="tw-mb-2">{t('title')}</div>
        {map(order.receiptsValidation, (validation) => (
          <div key={validation.type}>{`${t(`type.${validation.type}`, {
            defaultValue: validation.type,
          })}: ${formatUtils.formatBoolean(validation.success)}`}</div>
        ))}
      </Fragment>
    ),
    [t, order.receiptsValidation],
  );

  return (
    <Fragment>
      {showReceiptsValidation && (
        <div className="tw-flex tw-items-center">
          {labelled && (
            <div>
              {validationSuccess ? t('status.success') : t('status.failed')}
            </div>
          )}
          <Tooltip title={renderTooltipTitle()}>
            <ReceiptIcon
              fontSize="small"
              sx={{ ml: labelled ? 1 : 0 }}
              color={validationSuccess ? 'success' : 'error'}
            />
          </Tooltip>
        </div>
      )}
    </Fragment>
  );
};
