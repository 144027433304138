import { find } from 'lodash';
import { useMemo } from 'react';

import { META_KEY } from 'constants/meta.contants';

import { useUserContext } from './use-user-context.hook';

export const useMeta = () => {
  const { meta } = useUserContext();
  const telegramSupportBotUrl = useMemo(
    () => find(meta, { key: META_KEY.TELEGRAM_SUPPORT_BOT_URL })?.value,
    [meta],
  );
  return { telegramSupportBotUrl };
};
