import { Fab } from '@mui/material';
import { find } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import {
  ContactInfo,
  CopyText,
  DataGrid,
  DataGridColumnDefinition,
  DataWrapper,
  Money,
} from 'components';
import { AssetType, UserRole } from 'enums';
import { TranslationNamespace } from 'i18n';
import { Asset, Trader } from 'types';

type Props = {
  role?: UserRole.Trader | UserRole.Merchant;
  traders?: Trader[];
  queryResult: UseQueryResult<Asset[]>;
  onFundsRequest?: (asset: Asset) => void;
};

export const AssetsTable: React.FC<Props> = ({
  role,
  traders,
  queryResult,
  onFundsRequest,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'assets.columns',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);
  const { t: tFunds } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.funds_request',
  });

  const renderShopInfo = useCallback(
    (shopName?: string, website?: string) => (
      <div>
        <div>{shopName}</div>
        <div>{website}</div>
      </div>
    ),
    [],
  );

  const getInsuranceDepositAmount = useCallback(
    (userId: string) => {
      const trader = find(traders, { userId });
      return trader?.insuranceDepositAmount || 0;
    },
    [traders],
  );

  const renderAmount = useCallback(
    (asset: Asset) => {
      if (
        asset.type === AssetType.InsuranceDeposit &&
        asset.user.role === UserRole.Trader
      ) {
        const insuranceDepositAmount = getInsuranceDepositAmount(asset.userId);
        return (
          <div className="tw-flex">
            <Money value={asset.balance} />
            <div>{'/'}</div>
            <Money
              value={insuranceDepositAmount}
              symbol
              assetCurrencyId={asset.assetCurrencyId}
            />
          </div>
        );
      }

      return (
        <Money
          value={asset.balance}
          symbol
          assetCurrencyId={asset.assetCurrencyId}
        />
      );
    },
    [getInsuranceDepositAmount],
  );

  const roleColumnTKey = useMemo(() => {
    if (role) {
      return t(role);
    }
    return null;
  }, [role, t]);

  const columns = useMemo(
    (): DataGridColumnDefinition<Asset>[] => [
      {
        header: t('id'),
        valueGetter: (item) => <CopyText text={item.id} iconOnly />,
      },
      {
        header: roleColumnTKey || '',
        valueGetter: (item) => (
          <div>
            <span>{item?.user?.name}</span>
            <ContactInfo email={item?.user?.email} />
          </div>
        ),
        hidden: !roleColumnTKey,
      },
      {
        header: t('shop_id'),
        valueGetter: (item) =>
          renderShopInfo(item?.shop?.name, item?.shop?.webSiteUrl),
        hidden: role !== UserRole.Merchant,
      },
      {
        header: t('balance'),
        valueGetter: renderAmount,
      },
      {
        header: t('hold_balance'),
        valueGetter: (item) => (
          <Money
            value={item.holdBalance}
            symbol
            assetCurrencyId={item.assetCurrencyId}
          />
        ),
      },
      {
        header: t('type'),
        valueGetter: (item) => <div>{tCommon(`assets.type.${item.type}`)}</div>,
      },
      {
        header: tCommon('columns.actions'),
        valueGetter: (item) => (
          <Fab
            variant="extended"
            size="small"
            onClick={() => onFundsRequest?.(item)}
          >
            {tFunds('buttons.withdrawal')}
          </Fab>
        ),
        hidden: !onFundsRequest,
      },
    ],
    [
      t,
      roleColumnTKey,
      role,
      tCommon,
      renderAmount,
      onFundsRequest,
      renderShopInfo,
      tFunds,
    ],
  );

  return (
    <DataWrapper queryResult={queryResult}>
      <DataGrid columns={columns} data={queryResult.data} />
    </DataWrapper>
  );
};
