import {
  Assignment as AssignmentIcon,
  Payments as PaymentsIcon,
  People as PeopleIcon,
  CreditCard as CreditCardIcon,
  AttachMoney as AttachMoneyIcon,
  Timeline as TimelineIcon,
  AutoMode as AutoModeIcon,
  QueryStats as QueryStatsIcon,
  CompareArrows as CompareArrowsIcon,
} from '@mui/icons-material';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { notificationsApi } from 'api';
import { LayoutMenuSection } from 'components';
import { ROUTE_PATH } from 'constants/routes';
import { TIME_INTERVAL } from 'constants/time-interval.constants';
import { NotificationsContext, NotificationsContextProps } from 'context';
import { QueryKey } from 'enums';
import { TranslationNamespace } from 'i18n';
import { UserLayout } from 'layouts/User';

type Props = {
  children: React.ReactElement;
};

export const TraderLayout: React.FC<Props> = ({ children }) => {
  const { t } = useTranslation(TranslationNamespace.Trader, {
    keyPrefix: 'layout',
  });

  const { data } = useQuery(
    QueryKey.Notifications,
    notificationsApi.getTraderNotifications,
    {
      refetchInterval: TIME_INTERVAL.FIFTEEN_SECONDS,
      refetchIntervalInBackground: true,
    },
  );

  const notificationsData: NotificationsContextProps = useMemo(() => {
    const activePayinOrders = data?.activeOrdersCount || 0;
    const activePayoutOrders = data?.activePayoutOrdersCount || 0;
    return {
      activePayinOrders,
      activePayoutOrders,
    };
  }, [data?.activeOrdersCount, data?.activePayoutOrdersCount]);

  const activeOrdersCount = useMemo(() => {
    const activeOrdersCount = data?.activeOrdersCount || 0;
    const activePayoutOrdersCount = data?.activePayoutOrdersCount || 0;
    return activeOrdersCount + activePayoutOrdersCount;
  }, [data?.activeOrdersCount, data?.activePayoutOrdersCount]);

  const menuSections: LayoutMenuSection[] = useMemo(
    () => [
      {
        title: t('menu_sections.assets'),
        items: [
          {
            title: t('menu_items.assets.balances'),
            MuiIcon: AttachMoneyIcon,
            link: ROUTE_PATH.TRADER.MAIN,
          },
          {
            title: t('menu_items.assets.funds_requests'),
            MuiIcon: PaymentsIcon,
            link: ROUTE_PATH.TRADER.FUNDS_REQUESTS,
          },
          {
            title: t('menu_items.assets.internal_transfers'),
            MuiIcon: CompareArrowsIcon,
            link: ROUTE_PATH.TRADER.INTERNAL_TRANSFERS,
          },
          {
            title: t('menu_items.assets.balance_history'),
            MuiIcon: TimelineIcon,
            link: ROUTE_PATH.TRADER.BALANCE_HISTORY,
          },
        ],
      },
      {
        title: t('menu_sections.main'),
        items: [
          {
            title: t('menu_items.requisites'),
            MuiIcon: CreditCardIcon,
            link: ROUTE_PATH.TRADER.REQUISITES,
          },
          {
            title: t('menu_items.orders'),
            MuiIcon: AssignmentIcon,
            link: ROUTE_PATH.TRADER.ORDERS,
            ...(activeOrdersCount > 0 && {
              badge: activeOrdersCount,
            }),
          },
          {
            title: t('menu_items.order_automation'),
            MuiIcon: AutoModeIcon,
            link: ROUTE_PATH.TRADER.ORDER_AUTOMATION,
          },
          {
            title: t('menu_items.referral_system'),
            MuiIcon: PeopleIcon,
            link: ROUTE_PATH.TRADER.REFERRAL_SYSTEM,
          },
          {
            title: t('menu_items.statistics'),
            MuiIcon: QueryStatsIcon,
            link: ROUTE_PATH.TRADER.STATISTICS,
          },
        ],
      },
    ],
    [activeOrdersCount, t],
  );

  return (
    <NotificationsContext.Provider value={notificationsData}>
      <UserLayout menuSections={menuSections} children={children} />
    </NotificationsContext.Provider>
  );
};
