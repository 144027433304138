import moment from 'moment';
import React from 'react';

import { Timer, PayoutOrderStatusLabel } from 'components';
import { SHOW_PAYOUT_TIMER_WHEN_LESS_BY_STATUS } from 'constants/order.constants';
import { PayoutOrder } from 'types';

type Props = {
  order: PayoutOrder;
};

export const PayoutOrderStatusColumn: React.FC<Props> = ({ order }) => (
  <div>
    {SHOW_PAYOUT_TIMER_WHEN_LESS_BY_STATUS[order.status] &&
      order.statusTimeoutAt && (
        <Timer
          endTime={moment(order.statusTimeoutAt).toDate()}
          showWhenLess={SHOW_PAYOUT_TIMER_WHEN_LESS_BY_STATUS[order.status]}
        />
      )}
    <div>
      <PayoutOrderStatusLabel status={order.status} />
    </div>
  </div>
);
