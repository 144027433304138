import { Switch } from '@mui/material';
import { TFuncKey } from 'i18next';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RequisitesStatus } from 'enums';
import { TranslationNamespace } from 'i18n';

type ToggleMapItem = {
  label: TFuncKey<
    TranslationNamespace.Common,
    'features.requisites.requisites_list'
  >;
  updateStatus: RequisitesStatus;
};

type Props = {
  initialStatus: RequisitesStatus;
  updateStatus: (status: RequisitesStatus) => void;
  readOnly: boolean;
};

export const RequisitesStatusToggle: React.FC<Props> = ({
  initialStatus,
  updateStatus,
  readOnly,
}: Props) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.requisites_list',
  });

  const [status, setStatus] = useState(initialStatus);

  const statusActive = useMemo(
    () => status === RequisitesStatus.Active,
    [status],
  );
  const item = useMemo(() => toggleMap[status], [status]);

  const handleStatusChange = useCallback(() => {
    updateStatus(item.updateStatus);
    setStatus(item.updateStatus);
  }, [item.updateStatus, updateStatus]);
  return (
    <Switch
      title={t(item.label) as string}
      checked={statusActive}
      onChange={handleStatusChange}
      disabled={readOnly}
    />
  );
};

const toggleMap: Record<RequisitesStatus, ToggleMapItem> = {
  [RequisitesStatus.Active]: {
    label: 'actions.deactivate',
    updateStatus: RequisitesStatus.Inactive,
  },
  [RequisitesStatus.Inactive]: {
    label: 'actions.activate',
    updateStatus: RequisitesStatus.Active,
  },
};
