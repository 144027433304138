import React from 'react';
import { useQuery } from 'react-query';

import { assetBalanceHistoryApi, shopsApi, usersApi } from 'api';
import { BalanceHistory } from 'components';
import { QueryKey } from 'enums';
import { usePartialQuery } from 'hooks';
import { assetBalanceHistoryUtils } from 'utils';

export const AdminAssetBalanceHistoryPage: React.FC = () => {
  const { data: users = [] } = useQuery(
    QueryKey.Users,
    usersApi.getAllWithPlatform,
  );
  const { data: shops = [] } = useQuery(QueryKey.Shops, shopsApi.getAll);

  const queryResult = usePartialQuery(
    QueryKey.AssetBalanceHistory,
    assetBalanceHistoryApi.getAll,
    { useCachedCount: true },
  );

  return (
    <BalanceHistory
      queryResult={queryResult}
      additionalColumns={assetBalanceHistoryUtils.getAdminColumns()}
      additionalFilters={[
        ...assetBalanceHistoryUtils.getAdminFilters(users),
        ...assetBalanceHistoryUtils.getShopFilters(shops),
      ]}
    />
  );
};
