export enum FormFieldType {
  AccountNumber = 'account_number',
  IBAN = 'iban',
  Holder = 'holder',
  SwiftBic = 'swift_bic',
  IMPSAccountNumber = 'imps_account_number',
  IFSCCode = 'ifsc',
  BeneficiaryName = 'beneficiary_name',
  Phone = 'phone',
  UPIId = 'upi_id',
  Card = 'card',
  Cardholder = 'cardholder',
  IdCard = 'id_card',
  ERIP = 'erip',
  ExpirationDate = 'expiration_date',
  AccountLastDigits = 'account_last_digits',
}
