import axios from 'axios';

import { env } from 'config';
import { CurrencyExchange, CurrencyExchangeRate } from 'types';

import { createCrudApi } from './crud.api';

const url = `${env.apiUrl}/currency-exchange`;

const getRates = async (): Promise<CurrencyExchangeRate[]> =>
  (await axios.get(`${url}/rates`)).data;

export const currencyExchangeApi = {
  ...createCrudApi<CurrencyExchange>('/currency-exchange'),
  getRates,
};
