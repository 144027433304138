import React, { useMemo } from 'react';

import { OrderAutomationStatus } from 'enums';
import { orderAutomationUtils } from 'utils';

type Props = {
  status: OrderAutomationStatus;
};

export const OrderAutomationStatusLabel: React.FC<Props> = ({ status }) => {
  const textColorClassName = useMemo(() => {
    switch (status) {
      case OrderAutomationStatus.New:
        return 'tw-text-cyan-400';
      case OrderAutomationStatus.Retry:
        return 'tw-text-violet-500';
      case OrderAutomationStatus.Parsed:
        return 'tw-text-gray-500';
      case OrderAutomationStatus.WaitingConfirm:
        return 'tw-text-amber-500';
      case OrderAutomationStatus.Success:
      case OrderAutomationStatus.TemplateErrorHandled:
        return 'tw-text-green-700';
      case OrderAutomationStatus.TemplateError:
        return 'tw-text-red-600';
      case OrderAutomationStatus.NoRequisites:
      case OrderAutomationStatus.NoRequisitesGroup:
      case OrderAutomationStatus.NoTradeMethod:
      case OrderAutomationStatus.NotFound:
        return 'tw-text-red-500';
      case OrderAutomationStatus.OrderCancelled:
      case OrderAutomationStatus.OrderDisputed:
      case OrderAutomationStatus.AutomationDisabled:
      case OrderAutomationStatus.MultipleOrders:
      case OrderAutomationStatus.MultipleBanks:
      case OrderAutomationStatus.NoSourceInRequest:
      case OrderAutomationStatus.NoSource:
      case OrderAutomationStatus.DifferentParseMethodTypes:
      case OrderAutomationStatus.InvalidTemplate:
      case OrderAutomationStatus.DifferentParseMethodAmount:
      case OrderAutomationStatus.DifferentParseMethodValues:
      case OrderAutomationStatus.Spam:
      case OrderAutomationStatus.SpamSource:
        return 'tw-text-red-400';
      case OrderAutomationStatus.Payout:
        return 'tw-text-gray-500';
      case OrderAutomationStatus.Technical:
        return 'tw-text-amber-500';
    }
  }, [status]);

  return (
    <span className={textColorClassName}>
      {orderAutomationUtils.getStatusLabel(status)}
    </span>
  );
};
