import { Tab } from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';

import { ActiveFundsRequests } from './ActiveFundsRequests';
import { ArchiveFundsRequests } from './ArchiveFundsRequest';
import { CreateFundRequestDialog } from './CreateFundRequestDialog';

enum FundsRequestsTab {
  Active = 'active',
  Archive = 'archive',
}

export const ManageFundsRequestsPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.funds_request',
  });
  const { isAdmin } = useUser();
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  const handleCreate = useCallback(() => {
    setCreateDialogOpen(true);
  }, []);

  return (
    <div>
      <PageHeader
        title={t('title')}
        {...(isAdmin && {
          rightContentButton: { onClick: handleCreate },
        })}
      />
      <QueryTabs tabsEnum={FundsRequestsTab}>
        <Tab value={FundsRequestsTab.Active} label={t('tabs.active')} />
        <Tab value={FundsRequestsTab.Archive} label={t('tabs.archive')} />
      </QueryTabs>
      <QueryTabPanel value={FundsRequestsTab.Active}>
        <ActiveFundsRequests />
      </QueryTabPanel>
      <QueryTabPanel value={FundsRequestsTab.Archive}>
        <ArchiveFundsRequests />
      </QueryTabPanel>
      {isAdmin && (
        <CreateFundRequestDialog
          open={createDialogOpen}
          onClose={() => setCreateDialogOpen(false)}
        />
      )}
    </div>
  );
};
