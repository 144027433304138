import { Stack } from '@mui/material';
import React from 'react';
import { useQuery } from 'react-query';

import { assetsApi } from 'api';
import { AssetsTable, TotalBalance } from 'components';
import { QueryKey, UserRole } from 'enums';
import { useCalculateAssetBalances, useUser } from 'hooks';

export const MerchantsBalance: React.FC = () => {
  const { role } = useUser();
  const queryResult = useQuery(
    QueryKey.MerchantsAssets,
    assetsApi.getAllMerchantsAsRole(role),
  );

  const { balances } = useCalculateAssetBalances(queryResult.data || []);

  return (
    <Stack spacing={4}>
      <TotalBalance balances={balances} />
      <AssetsTable role={UserRole.Merchant} queryResult={queryResult} />
    </Stack>
  );
};
