import { find } from 'lodash';
import React from 'react';

import { useUserContext } from 'hooks';

type Props = {
  bankId: string;
  className?: string;
};

export const BankLabel: React.FC<Props> = ({ bankId, className }) => {
  const { banks } = useUserContext();
  return <span className={className}>{find(banks, { id: bankId })?.name}</span>;
};
