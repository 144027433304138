import { UserRole } from 'enums';

import { useUserContext } from './use-user-context.hook';

export const useUser = () => {
  const { user } = useUserContext();
  return {
    user,
    role: user.role,
    isAdmin: user.role === UserRole.Admin,
    isOperator: user.role === UserRole.Operator,
    isTechOperator: user.role === UserRole.TechOperator,
    isTrader: user.role === UserRole.Trader,
    isMerchant: user.role === UserRole.Merchant,
    isManager: [
      UserRole.Operator,
      UserRole.Admin,
      UserRole.TechOperator,
    ].includes(user.role),
  };
};
