import { Tab } from '@mui/material';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { UserWalletDetailsDialog } from 'features/wallets';
import { useQueryTab } from 'hooks';
import { TranslationNamespace } from 'i18n';

import { InternalUserWallets } from './InternalUserWallets';
import {
  PlatformWallets,
  PlatformWalletDetailsDialog,
} from './PlatformWallets';
import { UserWallets } from './UserWallets';

enum WalletsTabs {
  Platform = 'platform',
  Users = 'users',
  UsersExternal = 'users-external',
}

export const AdminWalletsPage: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.internal_wallets', // TODO: rename to wallets once old wallets will be removed
  });

  const { tab } = useQueryTab();

  const [createPlatformWalletDialogOpen, setCreatePlatformWalletDialogOpen] =
    useState(false);

  const [userWalletDialogOpen, setUsermWalletDialogOpen] = useState(false);

  const handleCreatePlatformWallet = useCallback(() => {
    setCreatePlatformWalletDialogOpen(true);
  }, []);

  const handleCreateUserWallet = useCallback(() => {
    setUsermWalletDialogOpen(true);
  }, []);

  const rightContentButton = useMemo(() => {
    if (tab === WalletsTabs.Platform) {
      return {
        onClick: handleCreatePlatformWallet,
      };
    } else if (tab === WalletsTabs.UsersExternal) {
      return {
        onClick: handleCreateUserWallet,
      };
    }
  }, [tab, handleCreatePlatformWallet, handleCreateUserWallet]);

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        divider={false}
        rightContentButton={rightContentButton}
      />
      <QueryTabs tabsEnum={WalletsTabs}>
        <Tab value={WalletsTabs.Platform} label={t('tabs.platform')} />
        <Tab value={WalletsTabs.Users} label={t('tabs.users')} />
        <Tab
          value={WalletsTabs.UsersExternal}
          label={t('tabs.users_external')}
        />
      </QueryTabs>
      <QueryTabPanel value={WalletsTabs.Platform}>
        <PlatformWallets />
      </QueryTabPanel>
      <QueryTabPanel value={WalletsTabs.Users}>
        <InternalUserWallets />
      </QueryTabPanel>
      <QueryTabPanel value={WalletsTabs.UsersExternal}>
        <UserWallets />
      </QueryTabPanel>
      <PlatformWalletDetailsDialog
        open={createPlatformWalletDialogOpen}
        onClose={() => setCreatePlatformWalletDialogOpen(false)}
      />
      <UserWalletDetailsDialog
        open={userWalletDialogOpen}
        onClose={() => setUsermWalletDialogOpen(false)}
      />
    </Fragment>
  );
};
