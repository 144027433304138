import styled from '@emotion/styled';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { ChipProps } from '@mui/material';
import { rgba } from 'polished';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledChip } from 'components/StyledChip';
import { OperationType } from 'enums';
import { TranslationNamespace } from 'i18n';

const getBackgroundColor = (props: any) => {
  switch (props.type) {
    case OperationType.Deposit:
      return props.theme.palette.success.main;
    case OperationType.Withdrawal:
      return props.theme.palette.grey[500];
  }
};

const StyledLabel = styled(StyledChip)<ChipProps & { type: OperationType }>`
  background: ${(props) => rgba(getBackgroundColor(props), 0.1)};
`;

type Props = {
  operationType: OperationType;
};

export const OperationTypeLabel: React.FC<Props> = ({ operationType }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'funds_request_operation',
  });

  switch (operationType) {
    case OperationType.Deposit:
      return (
        <StyledLabel
          type={operationType}
          label={t('deposit')}
          color="success"
          icon={<AddIcon />}
        />
      );
    case OperationType.Withdrawal:
      return (
        <StyledLabel
          type={operationType}
          label={t('withdrawal')}
          color="default"
          icon={<RemoveIcon />}
        />
      );
    default:
      return null;
  }
};
