import { find } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserContext } from 'hooks';
import { TranslationNamespace } from 'i18n';

type Props = {
  paymentTypeId: string;
  className?: string;
};

export const PaymentTypeLabel: React.FC<Props> = ({
  paymentTypeId,
  className,
}) => {
  const { paymentTypes } = useUserContext();
  const { t } = useTranslation(TranslationNamespace.Common);

  const paymentType = useMemo(
    () => find(paymentTypes, { id: paymentTypeId }),
    [paymentTypes, paymentTypeId],
  );
  const paymentTypeName = useMemo(
    () =>
      paymentType
        ? t(`features.requisites.types.${paymentType.code}`, {
            defaultValue: paymentType?.name,
          })
        : '',
    [paymentType, t],
  );

  return <span className={className}>{paymentTypeName}</span>;
};
