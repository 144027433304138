import axios from 'axios';

import { env } from 'config';
import { UserRole } from 'enums';
import { PaginatedData, QueryParams, PayoutRequisites } from 'types';

const url = `${env.apiUrl}/payout-requisites`;

const getAllAsRole =
  (role: UserRole) =>
  async (params: QueryParams): Promise<PaginatedData<PayoutRequisites>> =>
    (await axios.get(`${url}/${role}`, { params })).data;

const getAllForTraderAsRole =
  (role: UserRole) =>
  async (traderId: string): Promise<PayoutRequisites[]> =>
    (await axios.get(`${url}/${role}/trader/${traderId}`)).data;

const create = async (
  requisites: Partial<PayoutRequisites>,
): Promise<PayoutRequisites> => (await axios.post(url, requisites)).data;

const update = async ({
  id,
  requisites,
}: {
  id: string;
  requisites: Partial<PayoutRequisites>;
}): Promise<PayoutRequisites> =>
  (await axios.patch(`${url}/${id}`, requisites)).data;

const updateStatus = async ({
  id,
  data,
}: {
  id: string;
  data: Pick<PayoutRequisites, 'status'>;
}): Promise<PayoutRequisites> =>
  (await axios.patch(`${url}/${id}/status`, data)).data;

const findOne = async (id: string): Promise<PayoutRequisites> =>
  (await axios.get(`${url}/${id}`)).data;

const remove = async (id: string): Promise<PayoutRequisites> =>
  (await axios.delete(`${url}/${id}`)).data;

export const payoutRequisitesApi = {
  getAllAsRole,
  getAllForTraderAsRole,
  create,
  update,
  findOne,
  remove,
  updateStatus,
};
