import axios from 'axios';

import { env } from 'config';
import { UserRole } from 'enums';
import { CrudApi, PaginatedData, QueryParams } from 'types';

export const createCrudApi = <T>(path: string): CrudApi<T> => {
  const url = `${env.apiUrl}${path}`;

  const getAll = async (params?: QueryParams): Promise<T[]> =>
    (await axios.get(url, { params })).data;

  const getAllAsRole =
    (role: UserRole) =>
    async (params?: QueryParams): Promise<T[]> =>
      (await axios.get(`${url}/${role}`, { params })).data;

  const getAllPaginated = async (
    params?: QueryParams,
  ): Promise<PaginatedData<T>> =>
    (await axios.get(`${url}/paginated`, { params })).data;

  const getAllPaginatedAsRole =
    (role: UserRole) =>
    async (params?: QueryParams): Promise<PaginatedData<T>> =>
      (await axios.get(`${url}/${role}/paginated`, { params })).data;

  const getAllArchivePaginatedAsRole =
    (role: UserRole) =>
    async (params?: QueryParams): Promise<PaginatedData<T>> =>
      (await axios.get(`${url}/${role}/paginated/archive`, { params })).data;

  const getOne = async (id: string): Promise<T> =>
    (await axios.get(`${url}/${id}`)).data;

  const getOneAsRole =
    (role: UserRole) =>
    async (id: string): Promise<T> =>
      (await axios.get(`${url}/${role}/${id}`)).data;

  const update = async ({
    id,
    data,
  }: {
    id: string;
    data: Partial<T>;
  }): Promise<T> => (await axios.patch(`${url}/${id}`, data)).data;

  const updateAsRole =
    (role: UserRole) =>
    async ({ id, data }: { id: string; data: Partial<T> }): Promise<T> =>
      (await axios.patch(`${url}/${role}/${id}`, data)).data;

  const create = async (data: Partial<T>): Promise<T> =>
    (await axios.post(url, data)).data;

  const createAsRole =
    (role: UserRole) =>
    async (data: Partial<T>): Promise<T> =>
      (await axios.post(`${url}/${role}`, data)).data;

  const remove = async (id: string): Promise<T> =>
    (await axios.delete(`${url}/${id}`)).data;

  const removeAsRole =
    (role: UserRole) =>
    async (id: string): Promise<T> =>
      (await axios.delete(`${url}/${role}/${id}`)).data;

  const archive = async (id: string): Promise<T> =>
    (await axios.delete(`${url}/${id}/archive`)).data;

  const archiveAsRole =
    (role: UserRole) =>
    async (id: string): Promise<T> =>
      (await axios.post(`${url}/${role}/${id}/archive`)).data;

  return {
    getAll,
    getAllAsRole,
    getAllPaginated,
    getAllPaginatedAsRole,
    getAllArchivePaginatedAsRole,
    getOne,
    getOneAsRole,
    create,
    createAsRole,
    update,
    updateAsRole,
    remove,
    removeAsRole,
    archive,
    archiveAsRole,
  };
};
