import { TextFieldProps } from '@mui/material';
import { map } from 'lodash';
import React, { useMemo } from 'react';

import { FormikSelect } from 'components';
import { OrderAutomationStatus } from 'enums';
import { orderAutomationUtils } from 'utils';

type Props = {
  name: string;
  label: string;
  statuses?: OrderAutomationStatus[];
} & Partial<TextFieldProps>;

export const OrderAutomationStatusSelect: React.FC<Props> = ({
  name,
  label,
  statuses,
  ...rest
}) => {
  const options = useMemo(
    () =>
      map(statuses, (status) => ({
        value: status,
        label: orderAutomationUtils.getStatusLabel(status),
      })),
    [statuses],
  );

  return (
    <FormikSelect
      noneOption
      label={label}
      {...rest}
      name={name}
      options={options}
    />
  );
};
