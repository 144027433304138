import { Tab } from '@mui/material';
import { AxiosError } from 'axios';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { generatePath, useNavigate } from 'react-router-dom';

import { assetCurrencyApi, fiatCurrencyApi } from 'api';
import {
  CloseFormikDialogResult,
  PageHeader,
  QueryTabPanel,
  QueryTabs,
} from 'components';
import { NEW_ID } from 'constants/common.constants';
import { ROUTE_PATH } from 'constants/routes';
import { QueryKey } from 'enums';
import { useMutation, useQueryTab, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Currency, CurrencyDto } from 'types';
import { validationUtils } from 'utils';

import { AssetCurrencies } from './AssetCurrencies';
import { CurrenciesExchange } from './CurrenciesExchange';
import { CurrencyDetailsDialog } from './CurrencyDetailsDialog';
import { FiatCurrencies } from './FiatCurrencies';
import { FiatCurrencyExchange } from './FiatCurrencyExchange';
import { FiatCurrencyExchangeDetailsDialog } from './FiatCurrencyExchangeDetailsDialog';

enum CurrenciesTab {
  CurrencyExchanges = 'exchange',
  AssetCurrencies = 'asset',
  FiatCurrencies = 'fiat',
  FiatCurrenciesExchange = 'fiat-exchange',
}

export const CurrenciesPage: React.FC = () => {
  const queryClient = useQueryClient();
  const { t: tFeature } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.currencies',
  });
  const { t } = useTranslation(TranslationNamespace.Admin, {
    keyPrefix: 'pages.currencies',
  });
  const navigate = useNavigate();
  const { isAdmin, isTechOperator } = useUser();
  const { tab } = useQueryTab();

  const [createCurrencyDialogProps, setCreateCurrencyDialogProps] = useState<{
    open: boolean;
    title?: string;
  }>({ open: false });

  const [
    createFiatCurrenciesExchangeDialogOpen,
    setCreateFiatCurrenciesExchangeDialogOpen,
  ] = useState(false);

  const { mutate: createAssetCurrency } = useMutation<
    Currency,
    AxiosError,
    Partial<Currency>
  >(assetCurrencyApi.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.AssetCurrencies);
      setCreateCurrencyDialogProps({ open: false });
    },
  });
  const { mutate: createFiatCurrency } = useMutation<
    Currency,
    AxiosError,
    Partial<Currency>
  >(fiatCurrencyApi.create, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKey.FiatCurrencies);
      setCreateCurrencyDialogProps({ open: false });
    },
  });

  const currencyExchangeDetailsPath = useMemo(() => {
    if (isAdmin) {
      return ROUTE_PATH.ADMIN.CURRENCY_EXCHANGE_DETAILS;
    } else if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.CURRENCY_EXCHANGE_DETAILS;
    }
  }, [isAdmin, isTechOperator]);

  const handleOpenDetails = useCallback(
    ({ id }: { id: string }) => {
      navigate(generatePath(currencyExchangeDetailsPath!, { id }));
    },
    [currencyExchangeDetailsPath, navigate],
  );

  const handleCreateClick = useCallback(() => {
    switch (tab) {
      case CurrenciesTab.CurrencyExchanges:
        return handleOpenDetails({ id: NEW_ID });
      case CurrenciesTab.AssetCurrencies:
        return setCreateCurrencyDialogProps({
          open: true,
          title: tFeature('details.create_asset'),
        });
      case CurrenciesTab.FiatCurrencies:
        return setCreateCurrencyDialogProps({
          open: true,
          title: tFeature('details.create_fiat'),
        });
      case CurrenciesTab.FiatCurrenciesExchange:
        return setCreateFiatCurrenciesExchangeDialogOpen(true);
    }
  }, [tFeature, tab, handleOpenDetails]);

  const handleCreateCurrencyDialogClose = useCallback(
    ({ ok, data }: CloseFormikDialogResult<CurrencyDto>) => {
      if (ok && data) {
        const options = {
          onSuccess: () => {
            data.formikHelpers.resetForm();
          },
          onError: (error: AxiosError) => {
            data.formikHelpers.setErrors(validationUtils.getFormErrors(error));
          },
        };
        if (tab === CurrenciesTab.AssetCurrencies) {
          return createAssetCurrency(data.values, options);
        } else if (tab === CurrenciesTab.FiatCurrencies) {
          return createFiatCurrency(data.values, options);
        }
      }
      setCreateCurrencyDialogProps({ open: false });
    },
    [createAssetCurrency, createFiatCurrency, tab],
  );

  const handleFiatCurrenciesCrossExchangeDialogClose = useCallback(
    ({ ok, data }: CloseFormikDialogResult<CurrencyDto>) => {
      if (ok && data) {
        const options = {
          onSuccess: () => {
            data.formikHelpers.resetForm();
          },
          onError: (error: AxiosError) => {
            data.formikHelpers.setErrors(validationUtils.getFormErrors(error));
          },
        };
        if (tab === CurrenciesTab.AssetCurrencies) {
          return createAssetCurrency(data.values, options);
        } else if (tab === CurrenciesTab.FiatCurrencies) {
          return createFiatCurrency(data.values, options);
        }
      }
      setCreateFiatCurrenciesExchangeDialogOpen(false);
    },
    [createAssetCurrency, createFiatCurrency, tab],
  );

  return (
    <Fragment>
      <PageHeader
        title={t('title')}
        divider={false}
        rightContentButton={{
          onClick: handleCreateClick,
        }}
      />
      <QueryTabs tabsEnum={CurrenciesTab}>
        <Tab
          value={CurrenciesTab.CurrencyExchanges}
          label={t('tabs.currencies_exchange')}
          wrapped
        />
        <Tab
          value={CurrenciesTab.FiatCurrenciesExchange}
          label={t('tabs.fiat_currencies_cross_exchange')}
          wrapped
        />
        <Tab
          value={CurrenciesTab.AssetCurrencies}
          label={t('tabs.asset_currencies')}
          wrapped
        />
        <Tab
          value={CurrenciesTab.FiatCurrencies}
          label={t('tabs.fiat_currencies')}
          wrapped
        />
      </QueryTabs>
      <QueryTabPanel value={CurrenciesTab.CurrencyExchanges}>
        <CurrenciesExchange />
      </QueryTabPanel>
      <QueryTabPanel value={CurrenciesTab.FiatCurrenciesExchange}>
        <FiatCurrencyExchange />
      </QueryTabPanel>
      <QueryTabPanel value={CurrenciesTab.AssetCurrencies}>
        <AssetCurrencies />
      </QueryTabPanel>
      <QueryTabPanel value={CurrenciesTab.FiatCurrencies}>
        <FiatCurrencies />
      </QueryTabPanel>
      <CurrencyDetailsDialog
        {...createCurrencyDialogProps}
        onClose={handleCreateCurrencyDialogClose}
      />
      <FiatCurrencyExchangeDetailsDialog
        open={createFiatCurrenciesExchangeDialogOpen}
        onClose={handleFiatCurrenciesCrossExchangeDialogClose}
      />
    </Fragment>
  );
};
