// check backend type for statuses explanation
export enum PayoutOrderStatus {
  Requisites = 'requisites',
  Dispute = 'dispute',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Error = 'error',
  TraderAccept = 'trader_accept',
  TraderPayment = 'trader_payment',
}
