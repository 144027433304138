import { find, transform } from 'lodash';

import { FormikSelectOption, Money } from 'components';
import { AssetType } from 'enums';
import { Asset, Shop } from 'types';

const getAssetOptions = (assets?: Asset[]): FormikSelectOption[] =>
  transform(
    assets || [],
    (acc, asset) => {
      if (asset.type === AssetType.Balance) {
        acc.push({
          label: (
            <div>
              {asset.shop?.name && <div>{asset.shop.name}</div>}
              <Money
                key={asset.id}
                assetCurrencyId={asset.assetCurrencyId}
                value={asset.balance}
                symbol
              />
            </div>
          ),
          value: asset.id,
          disabled: !asset.id,
        });
      }
    },
    [] as FormikSelectOption[],
  );

const filterAssets = (
  assets: Asset[] = [],
  filters: { userId?: string; currencyId?: string } = {},
  shops: Shop[] = [],
) =>
  transform(
    assets,
    (acc, asset) => {
      if (filters.userId && asset.userId !== filters.userId) {
        return;
      }
      if (filters.currencyId && asset.assetCurrencyId !== filters.currencyId) {
        return;
      }

      acc.push(mergeAssetWithShop(asset, shops));
    },
    [] as Asset[],
  );

const mergeAssetWithShop = (asset: Asset, shops: Shop[] = []) => {
  const shop = find<Shop>(shops, (shop) => shop.id === asset.shopId);
  if (shop) {
    asset.shop = shop;
  }
  return asset;
};

export const assetUtils = {
  getAssetOptions,
  filterAssets,
};
