import { Button, Tab } from '@mui/material';
import { Fragment, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import { PageHeader, QueryTabPanel, QueryTabs } from 'components';
import { InternalTransferTab } from 'enums';
import { useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { Asset, Shop, User } from 'types';

import { ActiveInternalTransfers } from './ActiveInternalTransfers';
import { ArchiveInternalTransfers } from './ArchiveInternalTransfers';
import { AdminCreateInternalTransferDialog } from '../AdminCreateInternalTransferDialog';
import { CreateInternalTransferDialog } from '../CreateInternalTransferDialog';
import { ReceiveInternalTransferDialog } from '../ReceiveInternalTransferDialog';

type Props = {
  queryResultAssets: UseQueryResult<Asset[]>;
  queryResultUsers?: UseQueryResult<User[]>;
  queryResultShops?: UseQueryResult<Shop[]>;
};

export const InternalTransfersPage: React.FC<Props> = ({
  queryResultShops,
  queryResultUsers,
  queryResultAssets,
}) => {
  const { isTrader, isAdmin, isMerchant } = useUser();
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.internal_transfers',
  });
  const { t: tCommon } = useTranslation(TranslationNamespace.Common);

  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [receiveDialogOpen, setReceiveDialogOpen] = useState(false);
  const [createAdminDialogOpen, setCreateAdminDialogOpen] = useState(false);

  const rightContent = useMemo(() => {
    if (isAdmin) {
      return (
        <div>
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            onClick={() => setCreateAdminDialogOpen(true)}
          >
            {tCommon('buttons.create')}
          </Button>
        </div>
      );
    }

    if (isTrader || isMerchant) {
      return (
        <div className="tw-grid tw-grid-cols-2 tw-gap-2">
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            onClick={() => setCreateDialogOpen(true)}
          >
            {tCommon('buttons.create')}
          </Button>
          <Button
            size="medium"
            variant="outlined"
            color="primary"
            onClick={() => setReceiveDialogOpen(true)}
          >
            {tCommon('buttons.accept')}
          </Button>
        </div>
      );
    }

    return null;
  }, [isAdmin, isTrader, isMerchant, tCommon]);

  return (
    <div>
      <PageHeader title={t('title')} rightContent={rightContent} />
      <QueryTabs tabsEnum={InternalTransferTab}>
        <Tab value={InternalTransferTab.Active} label={t('tabs.active')} />
        <Tab value={InternalTransferTab.Archived} label={t('tabs.archive')} />
      </QueryTabs>
      <QueryTabPanel value={InternalTransferTab.Active}>
        <ActiveInternalTransfers queryResultShops={queryResultShops} />
      </QueryTabPanel>
      <QueryTabPanel value={InternalTransferTab.Archived}>
        <ArchiveInternalTransfers queryResultShops={queryResultShops} />
      </QueryTabPanel>
      {(isTrader || isMerchant) && (
        <Fragment>
          <CreateInternalTransferDialog
            open={createDialogOpen}
            assets={queryResultAssets.data}
            onClose={() => setCreateDialogOpen(false)}
          />
          <ReceiveInternalTransferDialog
            open={receiveDialogOpen}
            assets={queryResultAssets.data}
            onClose={() => setReceiveDialogOpen(false)}
          />
        </Fragment>
      )}
      {isAdmin && (
        <AdminCreateInternalTransferDialog
          open={createAdminDialogOpen}
          queryResultUsers={queryResultUsers}
          queryResultShops={queryResultShops}
          queryResultAssets={queryResultAssets}
          onClose={() => setCreateAdminDialogOpen(false)}
        />
      )}
    </div>
  );
};
