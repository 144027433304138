import i18next from 'i18next';
import { find } from 'lodash';

import { FilterDefinitionType, OperationType } from 'enums';
import { FilterDefinition, FundsRequestFilters, Shop, User } from 'types';

const getOperationLabel = (operation: OperationType) => {
  const { t } = i18next;
  const key = `funds_request_operation.${operation}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getCommonFilters = (
  users?: User[],
  shops?: Shop[],
): FilterDefinition<Partial<FundsRequestFilters>>[] => {
  const { t } = i18next;
  const filters: FilterDefinition<Partial<FundsRequestFilters>>[] = [
    {
      label: t('features.funds_request.filters.operation_type'),
      name: 'operationType',
      type: FilterDefinitionType.Enum,
      enum: OperationType,
      getDisplayName: fundsRequestUtils.getOperationLabel,
    },
  ];
  if (users) {
    filters.push({
      label: t('features.funds_request.filters.user'),
      name: 'userId',
      type: FilterDefinitionType.User,
      users,
      getDisplayName: (userId: string) => find(users, { id: userId })?.name,
    });
  }
  if (shops) {
    filters.push({
      label: t('features.funds_request.filters.shop'),
      name: 'shopId',
      type: FilterDefinitionType.Shop,
      shops,
      getDisplayName: (shopId: string) => find(shops, { id: shopId })?.name,
    });
  }
  return filters;
};

export const fundsRequestUtils = {
  getOperationLabel,
  getCommonFilters,
};
