import { Decimal } from 'decimal.js';
import { forEach } from 'lodash';

import { ROUND_DECIMALS } from 'constants/common.constants';

export class Math {
  private decimal: Decimal;

  get value() {
    return this.decimal.toNumber();
  }

  get stringValue() {
    return this.decimal.toString();
  }

  constructor(initialValue = 0) {
    this.decimal = new Decimal(initialValue);
  }

  plus(...values: number[]) {
    return this.runOperation('plus', ...values);
  }

  minus(...values: number[]) {
    return this.runOperation('minus', ...values);
  }

  divide(...values: number[]) {
    return this.runOperation('div', ...values);
  }

  multiply(...values: number[]) {
    return this.runOperation('mul', ...values);
  }

  round(
    decimals = ROUND_DECIMALS,
    rounding: Decimal.Rounding = Decimal.ROUND_HALF_UP,
  ) {
    this.decimal = this.decimal.toDecimalPlaces(decimals, rounding);
    return this;
  }

  roundUp(decimals = ROUND_DECIMALS) {
    return this.round(decimals, Decimal.ROUND_UP);
  }

  roundDown(decimals = ROUND_DECIMALS) {
    return this.round(decimals, Decimal.ROUND_DOWN);
  }

  private runOperation(
    operation: 'plus' | 'minus' | 'div' | 'mul',
    ...values: number[]
  ) {
    forEach(values, (value) => {
      this.decimal = this.decimal[operation](value);
    });
    return this;
  }
}
