import { concat, find } from 'lodash';
import React, { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from 'react-query';

import {
  CopyTextId,
  CrudPage,
  DataGridColumnDefinition,
  DateLabel,
  Money,
  StatusLabel,
} from 'components';
import { FilterDefinitionType } from 'enums';
import { useQueryFilters } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  FilterDefinition,
  PaginatedData,
  InternalTransfer,
  InternalTransferStatus,
  InternalTransferFilters,
  Shop,
} from 'types';
import { filtersUtils, formatUtils } from 'utils';

type Props = {
  queryResult: UseQueryResult<PaginatedData<InternalTransfer>>;
  queryResultShops?: UseQueryResult<Shop[]>;
  hideTitle?: boolean;
  additionalColumns?: DataGridColumnDefinition<InternalTransfer>[];
  filterDefinitions?: FilterDefinition<Partial<InternalTransferFilters>>[];
};

export const InternalTransfers: React.FC<Props> = ({
  queryResult,
  queryResultShops,
  additionalColumns = [],
  filterDefinitions: additionalFilters = [],
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.internal_transfers',
  });
  const { filters } = useQueryFilters<InternalTransferFilters>();

  const commonFilters: FilterDefinition<InternalTransferFilters>[] = useMemo(
    () => [
      {
        label: t('fields.code'),
        name: 'id',
        type: FilterDefinitionType.Text,
      },
    ],
    [t],
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<InternalTransfer>[] => [
      {
        header: t('fields.code'),
        valueGetter: (item) => <CopyTextId id={item.id} />,
      },
      {
        header: t('fields.date'),
        valueGetter: (item) => (
          <DateLabel>{formatUtils.formatDate(item.createdAt)}</DateLabel>
        ),
      },
      {
        header: t('fields.amount'),
        valueGetter: (item) => (
          <Money
            value={item.amount}
            assetCurrencyId={item.senderAsset?.assetCurrencyId}
            symbol
          />
        ),
      },
      {
        header: t('fields.status'),
        valueGetter: (item) => {
          let status = 'active';
          if (item.status === InternalTransferStatus.Active) {
            status = 'inactive';
          } else if (item.status === InternalTransferStatus.Cancelled) {
            status = 'error';
          }

          return (
            <StatusLabel
              label={t(`status.${item.status}`)}
              status={status as any}
            ></StatusLabel>
          );
        },
      },
      {
        header: t('fields.sender'),
        valueGetter: (item) => {
          const shopName = find(queryResultShops?.data, {
            id: item.senderAsset?.shopId,
          })?.name;

          return (
            <Fragment>
              <div>{item.senderUser.name}</div>
              {shopName && <div>{shopName}</div>}
            </Fragment>
          );
        },
      },
      ...(additionalColumns ? additionalColumns : []),
    ],
    [t, additionalColumns, queryResultShops],
  );

  const filtersDefinitions = useMemo(
    () =>
      concat(
        commonFilters,
        additionalFilters,
        filtersUtils.getCommonFilters(filters),
      ),
    [additionalFilters, commonFilters, filters],
  );

  return (
    <CrudPage
      filters={{ filtersDefinitions }}
      table={{ queryResult, columns, paginated: true }}
    />
  );
};
